import "./src/styles/global.css";

const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
if (darkQuery.matches) {
	document.documentElement.classList.add("dark");
}
darkQuery.addEventListener("change", (e) => {
	if (e.matches) {
		document.documentElement.classList.add("dark");
	} else {
		document.documentElement.classList.remove("dark");
	}
});
